.included-page-link-box {
    /* これまでの h1 と同じ見た目 */
    color: inherit;
    background-color: var(--h1-h2-background-color);
    padding: .6em;
    border: 0;
    margin: 0 0 .5em 0;

    /*noinspection CssNoGenericFontName*/
    font-family: var(--system-font-family);

    /* UA の h1 デフォルト */
    font-weight: bold;
}
