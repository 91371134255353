td.vote_label, #header td.vote_label, #footer td.vote_label {
    background-color: var(--plugin-vote-label-background-color);
}

td.vote_td1, #header td.vote_td1, #footer td.vote_td1 {
    background-color: var(--plugin-vote-td1-background-color);
}

td.vote_td2, #header td.vote_td2, #footer td.vote_td2 {
    background-color: var(--plugin-vote-td2-background-color);
}
