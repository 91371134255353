/*********************** レイアウト枠 ***********************/
body {
    margin: 0;
    padding: 0;
}

.container-wrapper {
    max-width: none; /* wiki 設定で上書きさせる */
    min-width: 980px; /* wiki 設定で上書きさせる */
    margin: 0 auto;
    text-align: left;
    padding: 10px 0;
}

.container {
    position: relative;
    margin: 0 20px;
}

.clearfix::after {
    content: "";
    display: block;
    clear: both;
}

/*********************** 3カラムレイアウト枠 ***********************/

.columns-container {
    display: grid;
    grid-template-rows: 1fr;
    min-height: 399px;
}
.column-left,
.column-center,
.column-right {
    grid-row: 1 / span 1;
    min-width: 0; /* はみ出る対策 */
}

.columns-container:not(.has-left-column):not(.has-right-column) {
    grid-template-columns: 1fr;
}
.columns-container:not(.has-left-column):not(.has-right-column) .column-center {
    grid-column: 1 / span 1;
}

.columns-container.has-left-column:not(.has-right-column) {
    grid-template-columns: 260px 1fr; /* wiki 設定で上書きされる */
}
.columns-container.has-left-column:not(.has-right-column) .column-left {
    grid-column: 1 / span 1;
}
.columns-container.has-left-column:not(.has-right-column) .column-center {
    grid-column: 2 / span 1;
}

.columns-container:not(.has-left-column).has-right-column {
    grid-template-columns: 1fr 160px;
}
.columns-container:not(.has-left-column).has-right-column .column-center {
    grid-column: 1 / span 1;
}
.columns-container:not(.has-left-column).has-right-column .column-right {
    grid-column: 2 / span 1;
}

.columns-container.has-left-column.has-right-column {
    grid-template-columns: 260px 1fr 160px; /* wiki 設定で上書きされる */
}
.columns-container.has-left-column.has-right-column .column-left {
    grid-column: 1 / span 1;
}
.columns-container.has-left-column.has-right-column .column-center {
    grid-column: 2 / span 1;
}
.columns-container.has-left-column.has-right-column .column-right {
    grid-column: 3 / span 1;
}

.columns-container.has-left-column .column-center {
    padding-left: 20px;
}
.columns-container.has-right-column .column-center {
    padding-right: 15px;
}

.container-wrapper {
    min-width: auto;
}

.navibar-desktop {
    display: block;
}
.navibar-mobile {
    display: none;
}

.navibar-container-all {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.navibar-container-all .toolbox-searchbar{
    margin-left: 16px;
}

@media (--layout-sm) {
    .container-wrapper {
        max-width: none;
        min-width: 320px;
        border: none;
    }
    .container {
        margin: 9px 8px;
    }
    .columns-container {
        display: block;
    }
    .columns-container.has-left-column .column-center,
    .columns-container.has-right-column .column-center {
        padding: 0;
    }
    div.img_margin {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .navibar-desktop {
        display: none;
    }
    .navibar-mobile {
        display: block;
        order: 1;
    }
    .navibar-container-all {
        flex-wrap: wrap;
    }
    .navibar-container-all .toolbox-searchbar{
        width: 100%;
        margin-left: 0;
        margin-top: -10px;
        margin-bottom: 8px;
        order: 0;
        text-align: right;
    }

    // #menubar, <-------- responsive-navigation に収納
    #sidebar {
        border: 1px solid #cccccc;
        padding: 8px 12px;
    }
}

/*********************** コンテンツ内基調整 ***********************/

#body {
    overflow-x: visible;
}

#menubar {
}

#sidebar {
    margin-bottom: 9px;
    word-break: break-all;
}

.h-scrollable {
    overflow-x: auto;
    text-align: left;
}

#footer .container > p,
#footer .container > div {
    margin: 0 10px;
}

#footer .footer-block-item-left {
    float: left;
}

#footer .footer-block-item-right {
    float: right;
    text-align: right;
}

#footer .footer-pageload,
#footer .footer-mixirss {
    display: inline-block;
}

#breadcrumbs {
    float: left;
}

#system-icon-container {
    float: right;
    display: flex;
    align-items: center;
    position: relative;

    // 内部の高さの予測値で固定したい
    min-height: 29px;

    & .single-system-icon {
        display: inline-block;
        padding: 0 8px;
    }

    & .is-frozen-icon {
        border: none;
        color: #888888;
        text-decoration: none;
    }
}
#control-panel {
    padding: 0.1em 0.5em;
    font-size: 11px;
}
#control-panel a {
    border:none;
    color: #888888;
    text-decoration: none;
}
#control-panel a:hover {
    background: transparent;
}

select#report_comment {
    width: 100%;
    height: 2em;
    margin: 0.5em 0;
}
