:root {
    --plugin-ntbr-dogear-color: rgba(0,0,0,0.5);
    --plugin-ntbr-background-color-td: #fff;
    --plugin-ntbr-background-color-th: #fff;
}

td.plugin-ntbr:not(.unveiled) {
    &::after {
        border-top-color: var(--plugin-ntbr-background-color-td);
        border-right-color: var(--plugin-ntbr-dogear-color);
        border-bottom-color: var(--plugin-ntbr-background-color-td);
        border-left-color: var(--plugin-ntbr-background-color-td);
    }
}

th.plugin-ntbr:not(.unveiled),
thead td.plugin-ntbr:not(.unveiled),
tfoot td.plugin-ntbr:not(.unveiled) {
    &::after {
        border-top-color: var(--plugin-ntbr-background-color-th);
        border-right-color: var(--plugin-ntbr-dogear-color);
        border-bottom-color: var(--plugin-ntbr-background-color-th);
        border-left-color: var(--plugin-ntbr-background-color-th);
    }
}
