@import "_common.css";
@import "_layout.css";
@import "_modules/_index.css";

:root {
    /* _common */
    --window-background-color: #dfebee;
    --link-text-color: #4096ee;
    --link-hover-text-color: black;
    --link-hover-background-color: #f5f5f5;
    --link-hover-text-decoration: none;
    --h1-h2-background-color: #ddd;
    --h3-border-color: #ebebeb;
    --h4-border-color: #ebebeb;
    --h5-h6-background-color: #ebebeb;
    --h1-title-background-color: #e5e5e5;
    --pre-background-color: #f5f5f5;
    --table-thead-tfoot-td-background-color: #eee;
    --table-thead-tfoot-th-background-color: #ddeefe;
    --table-th-background-color: #f3faff;
    --table-th-border-color: #c0c0c0;
    --table-td-background-color: #fff;
    --table-td-border-color: #c0c0c0;
    --table-conflict-thead-th-background-color: #ffffff;
    --table-conflict-thead-th-border-color: #c0c0c0;
    --new1-text-color: #c00;

    /* _modules */
    --plugin-calendar-top-background-color: #fff;
    --plugin-calendar-today-background-color: #ffffdd;
    --plugin-calendar-saturday-background-color: #eaedf8;
    --plugin-calendar-sunday-background-color: #f7efef;
    --plugin-calendar-blank-background-color: #fff;
    --plugin-calendar-weekday-background-color: #fff;
    --plugin-calendar-weeklabel-background-color: #e5e5e5;
    --plugin-minicalendar-top-background-color: #fff;
    --plugin-minicalendar-saturday-background-color: #eaedf8;
    --plugin-minicalendar-sunday-background-color: #f7efef;
    --plugin-minicalendar-blank-background-color: #fff;
    --plugin-minicalendar-weekday-background-color: #fff;
    --plugin-minicalendar-weeklabel-background-color: #e5e5e5;

    --plugin-vote-label-background-color: #eee;
    --plugin-vote-td1-background-color: #fff;
    --plugin-vote-td2-background-color: #fff;
}

h1, h2 {
    background-image: url('../image/web20_simple/web20_simple-slash.png');
}

h3 {
    border-width: 1px;
    padding: .6em;
    background-image: url('../image/web20_simple/web20_simple-slash.png');
}

h4 {
    border-width: 0 0 1px 0;
    padding: .6em;
}

h5, h6 {
    padding: .6em;
}

h1.title {
    font-size: 1.33em;
}

div#menubar h5 {
    background: url('../image/web20_simple/web20_simple-slash.png');
}

div#sidebar h2, h3, h4 {
    margin: 0 0 0 0;
    padding: 5px;
}

div#sidebar h5 {
    margin: 0 0 0 0;
    padding: 5px;
    background: url('../image/web20_simple/web20_simple-slash.png');
}

.style_calendar {
    background-color: #ccc;
}

span.new1 {
    font-weight: bold;
}

span.new3 {
    font-weight: bold;
}

span.new5 {
    font-weight: bold;
}

.ministyle_calendar {
    background-color: #ccc;
}

/*p.cads {*/
/*    background-color: #ebebeb;*/
/*    text-decoration: none;*/
/*}*/

/*.cads a {*/
/*    color: black;*/
/*    border-style: none;*/
/*}*/
