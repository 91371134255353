@import "../../_shared/css/preset.css";

/*********************** PCモードサポート ***********************/
body {
    -webkit-text-size-adjust: none;
}

/*********************** 基本フォント ***********************/
/* 見出しはつねにゴシック */
h1, h2, h3, h4, h5, h6 {
    font-family: var(--system-font-family);
}

/*********************** 文字間・行間 ***********************/
body {
    letter-spacing: 0.04em;
    overflow-wrap: break-word;
}
pre, table th, table td {
    overflow-wrap: normal;
}
* {
    line-height: 1.6em;
}
input, button {
    line-height: normal;
}

pre, dl, ol, ul, p, blockquote {
    margin-top: .7em;
    margin-bottom: .7em;
}

/*********************** ほか ***********************/
blockquote {
    border: 1px solid #ccc;
    margin-left: 32px;
}

body {
    background-color: var(--window-background-color);
    color: black;
}
.container-wrapper {
    background-color: #fff;
}

div#body a {
    line-break: strict;
    word-break: break-all;
    word-wrap: break-word;
}

a:link {
    color: var(--link-text-color);
    background-color: inherit;
    text-decoration: none;
}

a:active {
    color: var(--link-text-color);
    background-color: #cde;
    text-decoration: none;
}

a:visited {
    color: var(--link-text-color);
    background-color: inherit;
    text-decoration: none;
}

a:hover {
    color: var(--link-hover-text-color);
    background-color: var(--link-hover-background-color);
    text-decoration: var(--link-hover-text-decoration);
}

h1, h2 {
    color: inherit;
    background-color: var(--h1-h2-background-color);
    padding: .6em;
    border: 0;
    margin: 0 0 .5em 0;
}

h3 {
    color: inherit;
    background-color: #fff;
    border-style: solid;
    border-color: var(--h3-border-color);
    padding: .3em;
    margin: 0 0 .5em 0;
}

h4 {
    color: inherit;
    background-color: #fff;
    border-style: solid;
    border-color: var(--h4-border-color);
    padding: .3em;
    margin: 0 0 .5em 0;
}

h5, h6 {
    color: inherit;
    background-color: var(--h5-h6-background-color);
    padding: .3em;
    border: 0;
    margin: 0 0 .5em 0;
}

h1.title {
    background-color: var(--h1-title-background-color);
    font-weight: bold;
    padding: 10px 3px 10px 10px;
    border: 0;
    margin: 5px 0 10px 0;
}

dt {
    font-weight: bold;
    margin-top: 1em;
    margin-left: 1em;
}

dd {
    margin-left: 1em;
}

pre {
    border-top: #889 1px solid;
    border-bottom: #dde 1px solid;
    border-left: #889 1px solid;
    border-right: #dde 1px solid;
    padding: .5em;
    margin-left: 1em;
    margin-right: 2em;
    white-space: pre;
    color: black;
    background-color: var(--pre-background-color);
    overflow: auto;
}

img {
    border: none;
}

em {
    font-style: italic;
}

strong {
    font-weight: bold;
}

.style_table {
    padding: 0;
    border: 0;
    margin: .5em 0;
    text-align: left;
    color: inherit;
}

.style_td {
    padding: 5px;
    margin: 1px;
    color: inherit;
}

table {
    border-collapse: collapse;
    padding: 0;
    border: 0;
    margin: .5em 0;
    text-align: left;
    color: inherit;
}

table thead td, table tfoot td {
    background-color: var(--table-thead-tfoot-td-background-color);
}

table thead th, table tfoot th {
    background-color: var(--table-thead-tfoot-th-background-color);
}

table th {
    background-color: var(--table-th-background-color);
    border: 1px solid var(--table-th-border-color);
    padding: 5px;
    margin: 1px;
    text-align: center;
    color: inherit;
}

table td {
    background-color: var(--table-td-background-color);
    border: 1px solid var(--table-td-border-color);
    padding: 5px;
    margin: 1px;
    color: inherit;
}

/** ヘッダの wiki 名 */
.title2 {
    color: #000;
    font-size: 19px;
    font-weight: bold;
    border-style: none;
    background-color: transparent;
}

ul.list1 {
    list-style-type: disc;
}

ul.list2 {
    list-style-type: circle;
}

ul.list3 {
    list-style-type: square;
}

ol.list1 {
    list-style-type: decimal;
}

ol.list2 {
    list-style-type: lower-roman;
}

ol.list3 {
    list-style-type: lower-alpha;
}

div#body ul.list1,
div#body ol.list1,
div#body dl.list1,
div#body ul.list2,
div#body ol.list2,
div#body dl.list2,
div#body ul.list3,
div#body ol.list3,
div#body dl.list3 {
    margin-left: 16px;
    padding-left: 16px;
}

span.noexists {
    color: inherit;
    background-color: #fffacc;
}

.small {
    font-size: 90%;
}

.small1 {
    font-size: 90%;
}

.super_index {
    color: #d33;
    background-color: inherit;
    font-weight: bold;
    vertical-align: super;
}

a.note_super {
    color: #d33;
    background-color: inherit;
    font-weight: bold;
    vertical-align: super;
}

hr {
    border-width: 1px 0 0;
    color: #666;
    background-color: #666;
    height: 1px;
}

/* sizex plugin */
.size1 {
    font-size: xx-small;
}
.size2 {
    font-size: x-small;
}
.size3 {
    font-size: small;
}
.size4 {
    font-size: medium;
}
.size5 {
    font-size: large;
}
.size6 {
    font-size: x-large;
}
.size7 {
    font-size: xx-large;
}

.edit_form {
    clear: both;
}

div#menubar {
    word-break: break-all;
}

div#menubar ul,
div#menubar ol {
    margin: 0 0 0 1em;
    padding: 0 0 0 1em;
}

div#menubar ul li {
    margin: 0 0 0 .4em;
}

div#menubar h2 {
    margin: 0 0 0 0;
    padding: 5px;
}

div#menubar h3 {
    margin: 0 0 0 0;
    padding: 5px;
}

div#menubar h4 {
    margin: 0 0 0 0;
    padding: 5px;
}

div#menubar h5 {
    margin: 0 0 0 0;
    padding: 5px;
}

div#sidebar ul {
    margin: 0 0 0 .5em;
    padding: 0 0 0 1em;
}

div#sidebar ul li {
    margin: 0 0 0 .4em;
}

div#sidebar h2, h3, h4, h5 {
    margin: 0 0 0 0;
    padding: 5px;
}

/*div#body {*/
/*    padding: 0;*/
/*    margin: 0 0 0 .5em;*/
/*}*/

div#note {
    clear: both;
    padding: 0;
    margin: 0;
}

div#attach {
    clear: both;
    padding: 0;
    margin: 0 1%;
}

div#attach img {
    vertical-align: middle;
}

div.toolbar {
    clear: both;
    padding: 0;
    margin: 0 1%;
    text-align: right;
    white-space: nowrap;
}

div#lastmodified {
    color: #ccc;
    padding: 0;
    margin: -8px 1% .5em;
    text-align: right;
}

div#related {
    padding: 0;
    margin: 16px 1% 0 1%;
}

div#preview {
    color: inherit;
    background-color: #fff;
}

img#logo {
    float: left;
    margin: 12px 20px 10px 10px;
}

.anchor_super {
    font-size: xx-small;
    vertical-align: super;
}

.clear {
    display: block;
    clear: both;
}

div.counter {
    font-size: 70%;
}

span.diff_added {
    color: blue;
    background-color: inherit;
}

span.diff_removed {
    color: red;
    background-color: inherit;
}

hr.short_line {
    text-align: center;
    width: 80%;
}

h5.side_label {
    text-align: center;
}

ul.navi {
    margin: 0;
    padding: 0;
    text-align: center;
}

li.navi_none {
    display: inline;
    float: none;
}

li.navi_left {
    display: inline;
    float: left;
    text-align: left;
}

li.navi_right {
    display: inline;
    float: right;
    text-align: right;
}

span.comment_date {
    font-size: x-small;
}

span.new1 {
    color: var(--new1-text-color);
    background-color: transparent;
}

span.new3 {
    color: orangered;
    background-color: transparent;
    font-size: xx-small;
}

span.new5 {
    color: #008000;
    background-color: transparent;
    font-size: xx-small;
}

span.counter {
    font-size: 70%;
}

ul.popular_list {
    padding: 0 0 0 .5em;
    margin: 0 0 0 .5em;
    border: 0;
    word-wrap: break-word;
    word-break: break-all;
}

ul.recent_list {
    padding: 0 0 0 .5em;
    margin: 0 0 0 1em;
    border: 0;
    word-wrap: break-word;
    word-break: break-all;
}

ul.recent_list li {
    line-height: 110%;
}

div.img_margin {
    margin-left: 32px;
    margin-right: 32px;
}
#contents table div.img_margin {
    margin-left: 10%;
    margin-right: 10%;
}

div.img_nomargin {
    margin-left: -20px;
    margin-right: -20px;
}

div#topicpath {
    color: black;
    margin-top: 0.3em;
}

blockquote p {
    margin: .5em 1em;
}

div.trackback {
    font-size: 80%;
    text-align: right;
}

div.prevnext_l {
    float: left;
}

div.prevnext_r {
    float: right;
}

.dummyblock {
    width: 77%;
    float: left;
    display: block;
}

.center_form {
    text-align: center;
    padding: 4px 0 8px;
    margin: 0;
}

span.add_word {
    background-color: #ff6;
}

span.remove_word {
    background-color: #a0ffff;
}

div#signature {
    line-height: 122%;
}

img.ext {
    margin-left: 2px;
    vertical-align: baseline;
}

img.inn {
    margin-left: 2px;
    vertical-align: baseline;
}

#header table,
#footer table {
    background-color: transparent;
}

#header table th,
#header table td,
#footer table th,
#footer table td {
    margin: 0;
    padding: 0;
    border-color: transparent;
    background-color: transparent;
}

#header table td h1.title {
    font-size: 30px;
    font-weight: bold;
    background-color: transparent;
    padding: 0;
    border: 0;
    margin: 0;
}

table.attach_table {
    padding: 0;
    border: 0;
    margin: auto;
    text-align: left;
    color: inherit;
    background-color: #ccc;
}

th.attach_th {
    padding: 2px;
    margin: 1px;
    text-align: center;
    color: inherit;
    background-color: #fec;
}

td.attach_td1 {
    background-color: #fff;
}

td.attach_td2 {
    background-color: #cfc;
}

span.linkwarn {
    font-size: xx-small;
    font-weight: bold;
    color: #f00;
    background-color: #ff6;
}

span.linkwarn a {
    color: #f00;
    background-color: #ff6;
}

pre > code {
    white-space: pre-wrap;
    word-break: break-all;
}

.wiki-source > code {
    font-family: var(--system-font-family);
}
.wiki-source:not(pre) > code {
    white-space: pre-wrap;
}
pre.wiki-source > code {
    white-space: pre;
}

.result-message {
    margin: 0 0 1em;
    padding: 10px 20px;
    background-color: #ffe;
    border: 1px solid #666;
    border-radius: 6px;
    color: #333;
}
.result-message .result-message-icon {
    color: #666;
    font-size: 14px;
}

.warning-message {
    margin: 0 0 1em;
    padding: 10px 20px;
    background-color: #f8d7da;
    border: 1px solid #666;
    border-radius: 6px;
    color: #333;
}
.warning-message .warning-message-icon {
    color: #666;
    font-size: 14px;
}

table.conflict_diff > thead th {
    background-color: var(--table-conflict-thead-th-background-color);
    border: 1px solid var(--table-conflict-thead-th-border-color);
    padding: 1px;
    text-align: left;
}

.heading-after-first {
    margin-top: 8px;
}

input[name=attach_file],
input[name=attach] {
    margin: 8px 0;
}

/*********************** かつての _z.css から吸収したもの ***********************/
td, th {
    font-size: 1em;
}

h1, h2 {
    font-size: 1.1em;
}

h3, h4, h5, h6 {
    font-size: 1em;
}

h1.title {
    font-size: 1.33em;
}

table thead th, table tfoot th {
    font-size: 1em;
}

.super_index {
    font-size: 10px;
}

a.note_super {
    font-size: 10px;
}

div#lastmodified {
    font-size: 10px;
}

div#related {
    font-size: 10px;
}

div#footer {
    padding: 0;
}

div#signature {
    font-size: 11px;
}

/*div#cads {*/
/*    line-height: normal;*/
/*    letter-spacing: 0;*/
/*    margin-bottom: 1em;*/
/*}*/

/*p.cads {*/
/*    line-height: normal;*/
/*    text-decoration: none;*/
/*    background-color: #def;*/
/*}*/
